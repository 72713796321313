.employee-name {
  margin: auto;
  font-weight: 600;
  margin-bottom: 15px;
  width: 90%;
  text-align: left;
  color: rgba(#000000, 0.6);
}

.info-container {
  width: 90%;
  margin: auto;
  color: rgba(#000000, 0.6);

  .song-name {
    margin: 15px 0;
    font-weight: 600;
    text-align: left;
  }

  .artist {
    font-weight: 550;
    font-size: 0.8rem;
    text-align: left;
    margin-bottom: 15px;
  }

  .comment {
    font-weight: 500;
    font-size: 0.8rem;
    text-align: left;
    max-height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.hidden {
  visibility: hidden;
}

@media screen and (min-width: 711px) {
  .employee-name {
    width: 640px;
  }

  .info-container {
    width: 640px;
  }
}
