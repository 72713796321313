.loading-spinner {
  pointer-events: none;
  width: 2.5em;
  height: 2.5em;
  border: 0.4em solid transparent;
  border-color: #eee;
  border-top-color: #3e67ec;
  border-radius: 50%;
  animation: loadingspin 1s linear infinite;
}

@keyframes loadingspin {
  100% {
    transform: rotate(360deg);
  }
}

.spinner-body {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
