* {
  font-family: 'Montserrat', sans-serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* General styles */
.error-text {
  white-space: pre-wrap;
  margin-top: 10px;
  color: red;
}

.centered-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
