.month {
  font-weight: 600;
  width: 90%;
  margin: auto;
  font-size: 1.275rem;
  color: rgba(#000000, 0.6);
  text-align: left;
  margin-bottom: 15px;
  text-transform: uppercase;
}
