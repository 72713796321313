.header {
  height: 90px;
  width: 90%;
  margin: auto;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .header-content {
    flex: 1 0px;
    display: flex;
    align-items: center;

    .app-title {
      margin-left: 20px;
      font-size: 1.3rem;
      font-weight: 800;
      letter-spacing: -0.01em;
      color: rgba(#000000, 0.6);
    }

    .logo {
      height: 40px;
    }
  }
}

.change-view-icons {
  .calendar-icon {
    .calendar-light {
      position: relative;
      top: 1px;
      display: inherit;
    }
    .calendar-dark {
      position: relative;
      top: 1px;
      display: none;
    }
  }

  .calendar-icon:hover {
    .calendar-light {
      display: none;
    }
    .calendar-dark {
      display: inherit;
    }
  }
}

.month-picker {
  display: none !important;
}
